'use client';

import Cookies from 'js-cookie';
import { isEqual } from 'lodash'; // Import lodash for deep comparison
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { AUTH_TOKEN } from '@/constants/base.constant';
import useUserGetMe from '@/hooks/useUserGetMe';
import { User } from '@/types/user';

interface AuthContextProps {
    user: User | null;
    mutate: () => void;
    loading: boolean;
    errors: string[] | null;
    isAuthenticated: boolean;
    isVerified: boolean;
    isAdmin: boolean;
    auraInProgress: boolean;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({
    children,
    initialUser = null,
}: {
    children: ReactNode;
    initialUser?: User | null;
}) => {
    const token = Cookies.get(AUTH_TOKEN);
    // State to store the user, initialized with initialUser
    const [user, setUser] = useState<User | null>(initialUser);

    const { response = null, mutate, loading, errors } = useUserGetMe(!!token);

    const isAuthenticated = !!user;
    const isVerified = user?.email_verified ?? false;
    const isAdmin = user?.role?.id === 1;
    const auraStatus = user?.aura?.status || 'completed';
    const auraInProgress = loading
        ? false
        : auraStatus !== 'completed' && auraStatus !== 'not_started';

    // Update user state if response changes and the data is different
    useEffect(() => {
        if (!loading && response?.data?.user) {
            if (!user || !isEqual(response.data.user, user)) {
                setUser(response.data.user);
            }
        }
    }, [response, user, loading]);

    useEffect(() => {
        let isCancelled = false;
        const POLL_INTERVAL = 5000; // Poll every 5 seconds
        const TIMEOUT = 600000; // Stop polling after 10 minutes
        const startTime = Date.now();

        const pollAuraStatus = async () => {
            while (!isCancelled && auraInProgress) {
                await mutate(true);
                const updatedAuraStatus = response?.data?.user?.aura?.status;
                if (updatedAuraStatus === 'completed') {
                    break;
                }

                if (Date.now() - startTime >= TIMEOUT) {
                    console.error('Polling timed out');
                    break;
                }

                await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
            }
        };

        if (auraInProgress) {
            pollAuraStatus();
        }

        return () => {
            isCancelled = true;
        };
    }, [auraInProgress, mutate, response]);

    // Redirect to login page if not authenticated
    useEffect(() => {
        if (!loading && errors) {
            Cookies.remove(AUTH_TOKEN);
        }
    }, [loading, errors]);

    const values = {
        user,
        mutate,
        loading,
        errors,
        isAuthenticated,
        isVerified,
        isAdmin,
        auraInProgress,
    };

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
