import Icon, { IconNames } from '@/components/ui/Icon';
import { cn } from '@/utils/helper';

type ButtonVariant = 'primary' | 'primary--white' | 'secondary' | 'tertiary' | 'danger';
type ButtonSize = 'small' | 'large';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
    size?: ButtonSize;
    fullWidth?: boolean;
    iconFront?: IconNames;
    iconBack?: IconNames;
    rotateFront?: number;
    rotateBack?: number;
}

const Button: React.FC<ButtonProps> = ({
    children,
    onClick,
    disabled = false,
    variant = 'primary',
    size = 'large',
    fullWidth = false,
    type = 'button',
    className = '',
    iconFront,
    iconBack,
    rotateFront = 0,
    rotateBack = 0,
    ...rest
}) => {
    const baseClasses =
        'flex justify-center font-sans font-bold px-6 py-2.5 shadow-none items-center leading-none transition-colors duration-200 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring';

    const variantClasses: Record<ButtonVariant, string> = {
        primary:
            'bg-gray-333 border border-black text-neutral-100 hover:bg-black focus:ring-black hover:text-white',
        'primary--white':
            'bg-white border border-gray-484848 text-gray-484848 hover:text-black hover:border-black focus:ring-black',
        secondary:
            'bg-transparent text-gray-484848 border border-black hover:bg-black-150 hover:text-black',
        tertiary:
            'bg-transparent text-gray-484848 border border-transparent hover:underline hover:text-black',
        danger: 'bg-red-500 text-white hover:bg-red-600 focus:ring-red-500',
    };

    const sizeClasses: Record<ButtonSize, string> = {
        small: 'text-base leading-none font-medium rounded',
        large: 'text-xl leading-none font-semibold rounded',
    };

    const widthClass = fullWidth ? 'w-full' : '';

    const disabledClasses = disabled ? 'opacity-50 cursor-not-allowed' : '';

    const iconColor =
        variant === 'primary' ? 'fill-white' : variant === 'primary--white' ? 'fill-black' : '';

    return (
        <button
            type={type}
            className={cn(
                baseClasses,
                variantClasses[variant],
                sizeClasses[size],
                widthClass,
                disabledClasses,
                className,
            )}
            onClick={onClick}
            disabled={disabled}
            {...rest}
        >
            {/* Render the front icon with optional rotation if provided */}
            {iconFront && (
                <Icon
                    iconName={iconFront}
                    className={cn('mr-2 w-5 h-5', iconColor)} // Apply icon color conditionally
                    isDecorative
                    style={{ transform: `rotate(${rotateFront}deg)` }} // Rotate front icon
                />
            )}

            {/* Render children (button text) */}
            {children}

            {/* Render the back icon with optional rotation if provided */}
            {iconBack && (
                <Icon
                    iconName={iconBack}
                    className={cn('ml-2 w-4 h-4', iconColor)} // Apply icon color conditionally
                    isDecorative
                    style={{ transform: `rotate(${rotateBack}deg)` }} // Rotate back icon
                />
            )}
        </button>
    );
};

export default Button;
