import { FetchResultState } from '@/types/fetch';
import { User } from '@/types/user';
import api from '@/utils/api';

export interface MeResponseData {
    user: User;
}

const useUserGetMe = (enabled: boolean = true): FetchResultState<MeResponseData> => {
    if (!enabled) {
        return {
            response: null,
            errors: null,
            loading: false,
            mutate: () => {},
        };
    }

    const { response, errors, mutate } = api.get(
        '/me',
        {},
        {
            revalidateOnMount: false,
        },
    );

    const loading = !errors && !response;
    const errorMessages = errors?.[0] ? [errors[0]] : null;
    return { response, errors: errorMessages, loading, mutate };
};

export default useUserGetMe;
