import Cookies from 'js-cookie';
import useSWR from 'swr';

import { API_URL, AUTH_TOKEN } from '@/constants/base.constant';
import { ApiEndpoint } from '@/types/apiEndpoints';
import { FetchResultState, JSONResponse } from '@/types/fetch';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';

/**
 * Sets up the options for a fetch request, including the method, body, and authorization token.
 */
export const setupFetchOptions = async (
    method: HttpMethod,
    body?: any,
    token?: string,
): Promise<RequestInit> => {
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
    };

    const authToken = token || Cookies.get(AUTH_TOKEN);
    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }

    return {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
    };
};

/**
 * Performs an authenticated fetch request.
 */
export const authenticatedFetch = async <T>(
    endpoint: ApiEndpoint,
    options: RequestInit = {},
): Promise<FetchResultState<T>> => {
    let response: Response;
    let apiResponseBody: JSONResponse<T>;

    const result: FetchResultState<T> = {
        response: null,
        errors: null,
        loading: true,
    };

    try {
        response = await fetch(`${API_URL}/api/v1${endpoint}`, {
            ...options,
        });

        apiResponseBody = await response.json();
        if (!response.ok) {
            result.errors = [apiResponseBody.message || 'Failed to fetch data'];
        } else {
            result.response = {
                ...apiResponseBody,
                message: apiResponseBody.message || 'Success',
                data: apiResponseBody.data || null,
            };
        }
    } catch (error) {
        result.errors = [(error as Error).message];
    } finally {
        result.loading = false;
    }
    return result;
};

/**
 * Sends an API request using the specified method and body.
 */
const apiRequest = async (endpoint: ApiEndpoint, method: HttpMethod, body?: any): Promise<any> => {
    const options = await setupFetchOptions(method, body);
    return authenticatedFetch(endpoint, options);
};

/**
 * Fetches data from the specified API endpoint using GET method with SWR (for client-side).
 */
const fetcher = async (endpoint: ApiEndpoint): Promise<any> => {
    const options = await setupFetchOptions('GET');
    const response = await authenticatedFetch(endpoint, options);

    if (response.errors) {
        throw new Error(response.errors[0]);
    }

    return response.response;
};
const buildQueryString = (params: Record<string, any>): string => {
    const query = new URLSearchParams(params).toString();
    return query ? `?${query}` : '';
};

/**
 * API object with methods for making GET, POST, PUT, and DELETE requests.
 */
const api = {
    /**
     * Client-side GET using SWR for data fetching.
     */
    get: (
        endpoint: ApiEndpoint,
        params: Record<string, any> = {},
        options: {
            pollInterval?: number;
            revalidateIfStale?: boolean;
            revalidateOnMount?: boolean;
            revalidateOnFocus?: boolean;
            revalidateOnReconnect?: boolean;
            refreshInterval?: number;
            refreshWhenHidden?: boolean;
            refreshWhenOffline?: boolean;
            shouldRetryOnError?: boolean;
            dedupingInterval?: number;
            focusThrottleInterval?: number;
            loadingTimeout?: number;
            errorRetryInterval?: number;
            errorRetryCount?: number;
            fallbackData?: any;
            keepPreviousData?: boolean;
        } = {},
    ) => {
        const queryString = buildQueryString(params);
        const fullEndpoint = `${endpoint}${queryString}` as ApiEndpoint;

        const { data, error, mutate } = useSWR(fullEndpoint, () => fetcher(fullEndpoint), {
            suspense: false,
            revalidateIfStale: options.revalidateIfStale ?? true,
            revalidateOnMount: options.revalidateOnMount,
            revalidateOnFocus: options.revalidateOnFocus ?? true,
            revalidateOnReconnect: options.revalidateOnReconnect ?? true,
            refreshInterval: options.refreshInterval ?? options.pollInterval ?? 0,
            refreshWhenHidden: options.refreshWhenHidden ?? false,
            refreshWhenOffline: options.refreshWhenOffline ?? false,
            shouldRetryOnError: options.shouldRetryOnError ?? true,
            dedupingInterval: options.dedupingInterval ?? 2000,
            focusThrottleInterval: options.focusThrottleInterval ?? 5000,
            loadingTimeout: options.loadingTimeout ?? 3000,
            errorRetryInterval: options.errorRetryInterval ?? 5000,
            errorRetryCount: options.errorRetryCount,
            fallbackData: options.fallbackData,
            keepPreviousData: options.keepPreviousData ?? false,
        });

        const response = data || null;
        const loading = !error && !data;
        const errorMessages = error ? [error.message] : null;

        return { response, errors: errorMessages, loading, mutate };
    },

    /**
     * Server-side GET for SSR and middleware (without SWR).
     */
    getServer: async (endpoint: ApiEndpoint, token?: string): Promise<FetchResultState<any>> => {
        const options = await setupFetchOptions('GET', undefined, token);
        return authenticatedFetch(endpoint, options);
    },
    post: (endpoint: ApiEndpoint, body?: any) => apiRequest(endpoint, 'POST', body),
    put: (endpoint: ApiEndpoint, body?: any) => apiRequest(endpoint, 'PUT', body),
    delete: (endpoint: ApiEndpoint) => apiRequest(endpoint, 'DELETE'),
};

export default api;
