import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { ChangeEvent, useState } from 'react';

import Typography from '../../Typography';

import Skeleton from '@/components/ui/Skeleton';
import { cn } from '@/utils/helper';

interface InputGroupProps {
    label?: string;
    description?: string;
    type?: string;
    name: string;
    id: string;
    placeholder?: string;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    error?: string;
    disabled?: boolean;
    required?: boolean;
    autoComplete?: string;
    prefix?: string;
    loading?: boolean;
    rows?: number;
}

const InputGroup: React.FC<InputGroupProps> = ({
    label,
    description,
    type = 'text',
    name,
    id,
    placeholder,
    value,
    onChange,
    error,
    disabled,
    required,
    autoComplete,
    prefix,
    loading = false,
    rows = 3,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="w-full text-left">
            {label && (
                <label htmlFor={id} className={`block ${Typography.m1}  currentColor`}>
                    {loading ? <Skeleton height={24} width={100} /> : label}
                </label>
            )}
            <div className="relative mt-2 flex ">
                {prefix && (
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                        {prefix}
                    </span>
                )}
                {loading ? (
                    <Skeleton height={40} />
                ) : (
                    <div className="relative w-full">
                        {type === 'textarea' ? (
                            <textarea
                                name={name}
                                id={id}
                                className={cn(
                                    'flex w-full rounded-md border border-border bg-white px-4 py-3 min-w-[240px] text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
                                    {
                                        'rounded-r-md': !!prefix,
                                        'text-red-900 placeholder:text-red-300 focus:ring-red-500':
                                            !!error,
                                        'text-gray-900 placeholder:text-gray-400 focus:ring-indigo-600':
                                            !error,
                                        'bg-gray-200 cursor-not-allowed': disabled,
                                    },
                                )}
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                rows={rows}
                                disabled={disabled}
                                required={required}
                            />
                        ) : (
                            <input
                                type={
                                    type === 'password'
                                        ? showPassword
                                            ? 'text'
                                            : 'password'
                                        : type
                                }
                                name={name}
                                id={id}
                                className={cn(
                                    'flex h-[46px] w-full rounded-md border border-border bg-white px-4 py-3 min-w-[240px] text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
                                    {
                                        'rounded-r-md': !!prefix,
                                        'pr-10': type === 'password' && !error,
                                        'pr-16': type === 'password' && !!error,
                                        'text-red-900 placeholder:text-red-300 focus:ring-red-500':
                                            !!error,
                                        'text-gray-900 placeholder:text-gray-400 focus:ring-indigo-600':
                                            !error,
                                        'bg-gray-200 cursor-not-allowed': disabled,
                                    },
                                )}
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                aria-describedby={description ? `${id}-description` : undefined}
                                aria-invalid={error ? 'true' : undefined}
                                disabled={disabled}
                                required={required}
                                autoComplete={autoComplete}
                            />
                        )}
                        {type === 'password' && (
                            <button
                                type="button"
                                className={cn('absolute inset-y-0 right-0 flex items-center pr-3', {
                                    'right-8': !!error,
                                })}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                                ) : (
                                    <EyeIcon className="h-5 w-5 text-gray-400" />
                                )}
                            </button>
                        )}
                        {error && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <ExclamationCircleIcon
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {description && (
                <p
                    className={cn('mt-2 text-sm', {
                        'text-red-600': !!error,
                        'text-gray-500': !error,
                    })}
                    id={`${id}-description`}
                >
                    {loading ? <Skeleton height={16} width={200} /> : description}
                </p>
            )}
        </div>
    );
};

export default InputGroup;
