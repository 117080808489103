import { cn } from '@/utils/helper';
const baseHeadingStyles = ' font-serif leading-140 tracking-[-0.02em] inline-block';
const baseParagraphStyles = 'font-sans leading-140 tracking-[-0.015em] inline-block';
const baseMonoStyles = 'font-mono leading-140 tracking-[-0.01em] inline-block';

export const Typography = {
    h1: cn('text-[64px]', baseHeadingStyles), // 64px
    h2: cn('text-5xl', baseHeadingStyles), // 48px
    h3: cn('text-4xl', baseHeadingStyles), // 40px
    h4: cn('text-[32px]', baseHeadingStyles), // 32px

    p1: cn('text-base', baseParagraphStyles), // 16px
    p2: cn('text-xl', baseParagraphStyles), // 20px
    p3: cn('text-2xl', baseParagraphStyles), // 24px
    p4: cn('text-[32px]', baseParagraphStyles), // 32px
    p5: cn('text-[40px]', baseParagraphStyles), // 40px

    m1: cn('text-base', baseMonoStyles), // 16px
    m2: cn('text-xl', baseMonoStyles), // 20px
    m3: cn('text-2xl', baseMonoStyles), // 24px
};

export default Typography;
