type Route = {
    path: string;
    name: string;
};

type Routes = {
    HOME: Route;
    ACCOUNT: Route;
    ACCOUNT_PROFILE: Route;
    LOGIN: Route;
    LOGOUT: Route;
    PLANS: Route;
    PRICING: Route;
    TEAMS: Route;
    TEAM_DETAIL: Route;
    MEMBERSHIP: Route;
    ACCEPT_INVITE: Route;
    FORGOT_PASSWORD: Route;
    RESET_PASSWORD: Route;
    INTAKE: Route;
    ADMIN: Route;
    ADMIN_USERS: Route;
    ADMIN_ATTRIBUTES: Route;
    ADMIN_RECURRING_ATTRIBUTES: Route;
    ADMIN_PROMPTS: Route;
    ADMIN_USER_DETAIL: Route;
    ADMIN_CATEGORIES: Route;
    ADMIN_CATEGORY_DETAIL: Route;
    PROFILE_AURA: Route;
};

const ROUTES: Routes = {
    HOME: {
        path: '/',
        name: 'Aura',
    },
    ACCOUNT: {
        path: '/account',
        name: 'Account',
    },
    ACCOUNT_PROFILE: {
        path: '/account/profile',
        name: 'Settings',
    },
    LOGIN: {
        path: '/login',
        name: 'Login',
    },
    LOGOUT: {
        path: '/logout',
        name: 'Sign Out',
    },
    PLANS: {
        path: '/plans',
        name: 'Plans',
    },
    PRICING: {
        path: '/pricing',
        name: 'Pricing',
    },
    TEAMS: {
        path: '/account/teams',
        name: 'Teams',
    },
    TEAM_DETAIL: {
        path: '/account/teams/:id',
        name: 'Team Detail',
    },
    MEMBERSHIP: {
        path: '/account/membership',
        name: 'Membership',
    },
    ACCEPT_INVITE: {
        path: '/accept-invitation',
        name: 'Accept Invite',
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        name: 'Forgot Password',
    },
    RESET_PASSWORD: {
        path: '/reset-password',
        name: 'Reset Password',
    },
    INTAKE: {
        path: '/intake',
        name: 'Intake',
    },
    ADMIN: {
        path: '/admin',
        name: 'Dashboard',
    },
    ADMIN_USERS: {
        path: '/admin/users',
        name: 'Users',
    },
    ADMIN_USER_DETAIL: {
        path: '/admin/users/:id',
        name: 'User Details',
    },
    ADMIN_ATTRIBUTES: {
        path: '/admin/attributes',
        name: 'Archetype Attributes',
    },
    ADMIN_RECURRING_ATTRIBUTES: {
        path: '/admin/recurring-attributes',
        name: 'Recurring Attributes',
    },
    ADMIN_CATEGORIES: {
        path: '/admin/categories',
        name: 'Categories',
    },
    ADMIN_CATEGORY_DETAIL: {
        path: '/admin/categories/:id',
        name: 'Category Detail',
    },
    ADMIN_PROMPTS: {
        path: '/admin/prompts',
        name: 'Prompts',
    },
    PROFILE_AURA: {
        path: '/account/aura',
        name: 'Aura',
    },
};

export const PUBLIC_ROUTES: string[] = [
    ROUTES.ACCEPT_INVITE.path,
    ROUTES.LOGIN.path,
    ROUTES.PLANS.path,
    ROUTES.PRICING.path,
    ROUTES.FORGOT_PASSWORD.path,
    ROUTES.RESET_PASSWORD.path,
];

export default ROUTES;

/**
 * Generates a dynamic path by replacing route parameters with the given values.
 *
 * @example
 * const route = { path: '/account/teams/:id', name: 'Team Detail' };
 * const params = { id: 123 };
 * const path = getDynamicPath(route, params);
 * // path will be '/account/teams/123'
 */
export function getDynamicPath(route: Route, params: Record<string, string | number>): string {
    return Object.entries(params).reduce(
        (path, [key, value]) => path.replace(`:${key}`, String(value)),
        route.path,
    );
}
