import Cookies from 'js-cookie';

import { AUTH_TOKEN } from '@/constants/base.constant';
import { FetchResultState, Token } from '@/types/fetch';
import { User } from '@/types/user';
import { authenticatedFetch, setupFetchOptions } from '@/utils/api';

export interface LoginResponseData {
    user: User;
    token: Token;
}

type LoginResponse = FetchResultState<LoginResponseData>;

export const loginUser = async (
    email: string,
    password: string,
    rememberMe: number,
): Promise<LoginResponse> => {
    const options = await setupFetchOptions('POST', {
        email,
        password,
        remember_me: rememberMe,
    });

    const result = await authenticatedFetch<LoginResponseData>(`/login`, options);
    if (result.response?.data?.token) {
        const { token, expires_at } = result.response.data.token;
        await Cookies.set(AUTH_TOKEN, token, { expires: new Date(expires_at) });
    }
    return result;
};

export const logoutUser = async (): Promise<FetchResultState<any>> => {
    const options = await setupFetchOptions('POST');
    const results = authenticatedFetch<any>('/logout', options);
    Cookies.remove(AUTH_TOKEN);
    return results;
};
